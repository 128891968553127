import {
  autoElement,
  WebModuleLitTable,
  WebModuleLitTableColumnDef,
  WebmoduleToggle
} from '../../../webmodule-common/components/src/webmodule-components';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { Editor } from '@toast-ui/editor';
import { FileContainer, FileContainerManager } from '../data/file-content-container';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { html } from 'lit-html';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../../webmodule-common/interop/webmodule-interop';
import { stringToBase64 } from '../../../webmodule-common/other/blob-converters';
import {
  QuoteType,
  SupplierQuoteItemTableConfiguration,
  SupplierSettings
} from '../../api/supplier-api-interface-supplier';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { toggleItemInArray } from '../../../webmodule-common/other/array-helper';
import { FormInputSelectValue } from '../../../webmodule-common/other/FormInputView';

@autoElement()
export class SettingsGeneralView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private dataBinding: DataBinding;
  private dataTracker: DataTracker;

  private maxTableConfigCount: number;

  private termsAndContitionsManager: FileContainerManager;
  private editor?: Editor;
  private forms: FormInputAssistant;

  constructor() {
    super();
    this.pageFragment = 'general';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataBinding.allowMissingElements = true; // Removed Config items
    this.dataTracker = new DataTracker(this.dataBinding);
    this.forms = new FormInputAssistant(this.dataTracker, () => this.readonly);
    this.forms.immediateBindingUpdate = true;
    this.termsAndContitionsManager = new FileContainerManager(
      new FileContainer(this.settingsManager.supplierSettings.termsAndConditionsVirtualPath),
      () => tlang`Terms & Conditions`
    );
    this.termsAndContitionsManager.afterSave.push(async () => {
      this.settingsManager.updateInput.base64TermsAndConditions = stringToBase64(
        this.termsAndContitionsManager.fileContent
      );
      await this.settingsManager.saveSettings();
      this.requestUpdate();
    });
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.settings),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('validityPeriod', FieldType.int);
    addField('leadPeriod', FieldType.int);
    addField('allowCustomItem', FieldType.boolean);

    this.dataTracker.addDynamic(
      'estimate',
      FieldType.boolean,
      () => this.settings.allowedQuoteTypes.includes(QuoteType.Estimate),
      (value: boolean) => {
        toggleItemInArray(this.settings.allowedQuoteTypes, QuoteType.Estimate, value);
      }
    );

    this.dataTracker.addDynamic(
      'displaySample',
      FieldType.boolean,
      () => this.settings.allowedQuoteTypes.includes(QuoteType.Display),
      (value: boolean) => {
        toggleItemInArray(this.settings.allowedQuoteTypes, QuoteType.Display, value);
      }
    );
    this.dataTracker.addDynamic(
      'warrantyItem',
      FieldType.boolean,
      () => this.settings.allowedQuoteTypes.includes(QuoteType.Warranty),
      (value: boolean) => {
        toggleItemInArray(this.settings.allowedQuoteTypes, QuoteType.Warranty, value);
      }
    );

    this.maxTableConfigCount = this.settings.quoteItemTableConfiguration?.length ?? 0;
    for (let i = 0; i < this.maxTableConfigCount; i++) this.addTableConfig(i);
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];

    const validityPeriod = this.settings.validityPeriod;
    const leadPeriod = this.settings.leadPeriod;
    const tacText = this.editor?.getMarkdown();

    if (validityPeriod == null || validityPeriod < 0) {
      errors.push(tlang`Validity Period "${validityPeriod}" must be greater than or equal to 0`);
    }

    if (leadPeriod == null || leadPeriod < 0) {
      errors.push(tlang`Lead Period "${leadPeriod}" must be greater than or equal to 0`);
    }

    if (isEmptyOrSpace(tacText)) {
      errors.push(tlang`No Terms And Condition specified`);
    }

    if (
      this.settings.quoteItemTableConfiguration &&
      this.settings.quoteItemTableConfiguration.length > 0 &&
      !this.settings.quoteItemTableConfiguration?.every(x => !isEmptyOrSpace(x.code))
    ) {
      errors.push(tlang`Specify a Code for each row in the Quote Item Summary Configuration section`);
    }

    return errors;
  }

  async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) {
      this.dataTracker.applyChangeToValue();
    }

    this.termsAndContitionsManager.fileContent = this.editor?.getMarkdown() ?? '';
  }

  public internalDataChanged(): boolean {
    return this.settingsManager.changed() || this.termsAndContitionsManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.settingsManager.needsSettings(true);
    await this.settingsManager.needsSystemConfiguration();

    await this.termsAndContitionsManager.needsFile();

    const element = document.querySelector('#generalSettingsEditor') as HTMLElement | null;
    if (element) {
      this.editor = new Editor({
        el: element,
        previewStyle: 'vertical',
        height: '500px',
        initialValue: this.termsAndContitionsManager.fileContent,
        usageStatistics: false,
        initialEditType: 'wysiwyg'
      });
    }

    this.requestUpdate();
  }

  addTableConfig(index: number) {
    this.dataTracker.addDynamic(
      this.getFieldCode(index),
      FieldType.string,
      () => {
        if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
          return '';
        return this.settings.quoteItemTableConfiguration[index].code;
      },
      value => {
        if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
          return;
        this.settings.quoteItemTableConfiguration[index].code = (value as string) ?? '';
      }
    );

    this.dataTracker.addDynamic(
      this.getFieldDisplayed(index),
      FieldType.boolean,
      () => {
        if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
          return false;
        return this.settings.quoteItemTableConfiguration[index].defaultDisplay;
      },
      value => {
        if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
          return false;
        this.settings.quoteItemTableConfiguration[index].defaultDisplay = (value as boolean) ?? false;

        return this.settings.quoteItemTableConfiguration[index].defaultDisplay;
      }
    );

    this.dataTracker.addDynamic(
      this.getFieldCanToggle(index),
      FieldType.boolean,
      () => {
        if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
          return false;
        return this.settings.quoteItemTableConfiguration[index].canSwitch;
      },
      value => {
        if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
          return false;
        this.settings.quoteItemTableConfiguration[index].canSwitch = (value as boolean) ?? false;

        return this.settings.quoteItemTableConfiguration[index].canSwitch;
      }
    );
  }

  async applyBinding() {
    await this.prepareForSave();
  }

  protected async revertChanges(): Promise<boolean> {
    this.settingsManager.reset();
    return true;
  }

  protected getCaption(): Snippet {
    return tlang`General Settings`;
  }

  protected async internalSaveData(): Promise<boolean> {
    await this.termsAndContitionsManager.save(true);

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`${this.getCaption()} saved`);
      this.requestUpdate();
      return true;
    }

    return false;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = this.forms;
    const addTableConfigEvent = () => {
      if (!this.settings.quoteItemTableConfiguration) this.settings.quoteItemTableConfiguration = [];

      this.settings.quoteItemTableConfiguration.push({
        code: '',
        title: '',
        canSwitch: true,
        defaultDisplay: true,
        systemDefault: false
      });

      const currentCount = this.settings.quoteItemTableConfiguration.length ?? 0;

      if (currentCount > this.maxTableConfigCount) {
        this.addTableConfig(this.maxTableConfigCount);
        this.maxTableConfigCount = currentCount;
      }

      this.requestUpdate();
    };

    const colDef = this.getColums();

    const addSummaryTable = () => {
      if (!this.settings.quoteItemTableConfiguration || this.settings.quoteItemTableConfiguration.length == 0)
        return html``;

      return html`
        <webmodule-lit-table
          id="settings-quote-item-config-table"
          class="settings-quote-item-config-table"
          .rowClass=${'tr'}
          .colClass=${'column'}
          .tablestyle="nestedtable"
          .columns=${colDef}
          .pageLength="100"
          .data=${this.settings.quoteItemTableConfiguration ?? []}
          .clickrows=${false}
        >
        </webmodule-lit-table>
      `;
    };

    const addSummaryConfigButtonTemplate = () => {
      return html` <div class="row">
        <div class="col-2 mb-3 mt-3">
          ${forms.buttonPrimary(tlang`Add Column Configuration`, {
            readonly: this.isReadonly,
            events: {
              click: addTableConfigEvent
            }
          })}
        </div>
      </div>`;
    };

    return html` <div>
      <form id="formGeneralSettings" class="form-one-col">
        <h2>${tlang`%%quote%% Settings`}</h2>
        <div class="row">
          <div class="col-md-6">
            ${forms.intRequired('validityPeriod', tlang`Validity Period (days)`, { min: 0, max: 365 })}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            ${forms.intRequired('leadPeriod', tlang`Lead Period (Weeks)`, { min: 0, max: 52 })}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-column form-toggle-labled">
            <div class="row mb-2 mt-1 form-col-item">
              <label class="form-col-label">Supported Quote Types </label>
              <div class="form-col-input">
                ${forms.switch('estimate', tlang`%%estimate%%`, {
                  readonly: this.isReadonly,
                  class: 'me-3'
                })}
                ${forms.switch('displaySample', tlang`%%display-sample%%`, {
                  readonly: this.isReadonly,
                  class: 'me-3'
                })}
                ${forms.switch('warrantyItem', tlang`%%warranty-item%%`, {
                  readonly: this.isReadonly,
                  class: 'me-3'
                })}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-column form-toggle-labled">
            <div class="row mb-2 mt-2 form-col-item">
              <label class="form-col-label">Allow Custom Item Request </label>

              <div class="form-col-input">${forms.switch('allowCustomItem')}</div>
            </div>
          </div>
        </div>

        <h2>${tlang`%%quote-item%% Summary Configuration`}</h2>
        ${addSummaryTable()} ${addSummaryConfigButtonTemplate()}

        <h2>${tlang`%%purchase-order%% Terms & Conditions`}</h2>
        <div class="row">
          <div id="generalSettingsEditor"></div>
        </div>
      </form>
    </div>`;
  }

  private getFieldCode(index): string {
    return `quote_item_config_code_${index}`;
  }

  private getFieldDisplayed(index): string {
    return `quote_item_config_display_${index}`;
  }

  private getFieldCanToggle(index): string {
    return `quote_item_config_toggle_${index}`;
  }

  private getColums(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];
    cols.push({
      title: tlang`Title`,
      classes: 'colpxmax-400 no-pseudo quote-item-summary-config-code',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, _item: unknown, index: number) => {
        const field = this.getFieldCode(index);

        const optionConverter = (value: SupplierQuoteItemTableConfiguration) => {
          const x: FormInputSelectValue = { text: value.title, value: value.code, disabled: false };
          return x;
        };
        return html`
          <div class="col-md-12">
            ${this.forms.arraySelect(
              field,
              this.settingsManager.systemSettings?.supplierQuoteItemConfigurations ?? [],
              optionConverter,
              { placeholder: tlang`Select Column` }
            )}
          </div>
        `;
      }
    });

    cols.push({
      title: tlang`Default Display`,
      classes: 'colpxmax-200 quote-item-summary-config-display',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, _item: unknown, index: number) => {
        const field = this.getFieldDisplayed(index);
        return this.forms.switch(field, undefined);
      }
    });
    cols.push({
      title: tlang`Dealer can show or hide`,
      classes: 'colpxmax-200 quote-item-summary-config-toggle',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, _item: unknown, index: number) => {
        // const rowItem = item as QuoteItemSummaryConfig;
        const field = this.getFieldCanToggle(index);

        const toggleCanToggle = async (e: CustomEvent) => {
          const target = e.target as WebmoduleToggle;

          this.dataBinding.setValue(field, target.checked.toString());
          target.value = target.checked.toString();
          await this.prepareForSave();
        };

        return html`
          <webmodule-toggle
            id=${this.dataBinding.field(field)}
            ?checked=${this.dataTracker.getObjectValue(field)}
            value=${this.dataTracker.getObjectValue(field)}
            size="small"
            @webmodule-change=${toggleCanToggle}
          >
          </webmodule-toggle>
        `;
      }
    });

    cols.push({
      title: tlang``,
      classes: 'colpxmax-200 quote-item-summary-config-action',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, _item: unknown, index: number) => {
        const removeConfig = () => {
          this.settings.quoteItemTableConfiguration?.splice(index, 1);
          this.requestUpdate();
        };

        return html` <div class="settings-quote-item-summary-actions">
          <webmodule-icon-button library="fa" name="trash-can" @click=${removeConfig}></webmodule-icon-button>
        </div>`;
      }
    });

    return cols;
  }
}
