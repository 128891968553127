import { autoElement } from '../../../webmodule-common/components/src/webmodule-components';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { html } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { tlang } from '../../../webmodule-common/other/language/lang';
import type { PromiseTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import type { SupplierSettings } from '../../api/supplier-api-interface-supplier';
import type { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { tlangMarkdown } from '../../../webmodule-common/other/general/markdown';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { DataTracker } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { fileIsImageFile } from '../../../webmodule-common/other/ui/file-helper-functions';
import { isSomething } from '../../../webmodule-common/other/general/nothing';
import { getAbsoluteVirtualFilePath } from '../../api/blob-helper';

@autoElement()
export class SettingsLogoBrandingView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private dataTracker: DataTracker;
  private dataBinding: DataBinding;
  private newPositiveLogo?: File;
  private newNegativeLogo?: File;
  private positiveLogoName = 'supplier-positive-logo';
  private negativeLogoName = 'supplier-negative-logo';

  constructor() {
    super();
    this.pageFragment = 'branding';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);

    this.dataTracker.addImageLink(
      this.positiveLogoName,
      () => getAbsoluteVirtualFilePath(this.settings.positiveLogoVirtualPath, './assets/images/sample-logo-colour.png'),
      value => {
        this.newPositiveLogo = value;
      }
    );

    this.dataTracker.addImageLink(
      this.negativeLogoName,
      () => getAbsoluteVirtualFilePath(this.settings.negativeLogoVirtualPath, './assets/images/sample-logo-colour.png'),
      value => {
        this.newNegativeLogo = value;
      }
    );
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];

    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (this.newPositiveLogo && !fileIsImageFile(this.newPositiveLogo)) {
      errors.push(tlang`Please provide a valid Color Logo`);
    }
    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (this.newNegativeLogo && !fileIsImageFile(this.newNegativeLogo)) {
      errors.push(tlang`Please provide a valid White Logo`);
    }
    return errors;
  }

  async prepareForSave(): Promise<void> {
    this.dataTracker.applyChangeToValue();
  }

  public internalDataChanged(): boolean {
    return isSomething(this.newNegativeLogo, this.newPositiveLogo);
  }

  async onEnter(): Promise<void> {
    this.requestUpdate();
  }

  protected getCaption(): Snippet {
    return tlang`Branding`;
  }

  protected revertChanges(): Promise<boolean> {
    this.newNegativeLogo = undefined;
    this.newNegativeLogo = undefined;
    this.settingsManager.reset();
    return Promise.resolve(true);
  }

  protected async internalSaveData(): Promise<boolean> {
    // attempt to upload a logo from the given element and get its uploaded file path
    const newPositiveLogo = await this.dataBinding.createVirtualFile(this.newPositiveLogo);
    const newNegativeLogo = await this.dataBinding.createVirtualFile(this.newNegativeLogo);

    this.settingsManager.updateInput.base64PositiveLogo = newPositiveLogo?.content ?? null;
    this.settingsManager.updateInput.base64NegativeLogo = newNegativeLogo?.content ?? null;
    this.settingsManager.updateInput.positiveLogoFileName = newPositiveLogo
      ? `positiveBrandingLogo${newPositiveLogo?.extension}`
      : null;
    this.settingsManager.updateInput.negativeLogoFileName = newNegativeLogo
      ? `negativeBrandingLogo${newNegativeLogo?.extension}`
      : null;

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Branding saved`);
      this.dataTracker.removeFilesFromEditors();
      this.requestUpdate();
      return true;
    }
    return false;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const form = new FormInputAssistant(this.dataTracker, false, true);
    const brandingMsg = tlangMarkdown`${'ref:FranchiseeBrandingView:uploadMsg:1'}
Please upload your business logo<br>
This will be incorporated into documents sent to !!client!! and !!franchisee!!, such as !!estimate!!/!!invoice!!/!!purchase-order!!.

Choose a logo to appear at the top of PDFs and reports you print or send.<br>
The minimum permitted size is **800(W) x 400(H)** pixels.<br>
The ideal size of your logo is **1600(W) x 800(H)** pixels.<br>
The image must be **300DPI** in quality and in the **jpg** format on a white background.<br>
If the aspect ratio of your logo is different, it will be scaled down in proportion to fit the template constraints.
`;

    return html` <div>
      <form id="CompanyBrandingForm" class="form-two-col">
        <div class="row branding-wrapper">
          <div class="col-sm-6 form-column">
            <h4 class="section-header branding-image-header">${tlang`Colour Logo`}</h4>
            <div class="branding-image-description">
              ${tlang`This logo appears on the top left hand corner of your masthead`}
            </div>
            ${form.imagePicker(this.positiveLogoName, tlang`Color Logo`)}
          </div>
          <div class="col-sm-6 form-column">
            <h4 class="section-header branding-image-header">${tlang`White Logo`}</h4>
            <div class="branding-image-description">
              ${tlang`This logo appears on printed reports ( PDF ) and the footer of the %dealership%`}
            </div>
            ${form.imagePicker(this.negativeLogoName, tlang`White Logo`)}
          </div>
        </div>
        <h2>${tlang`Specifications`}</h2>
        <p>${brandingMsg}</p>
      </form>
    </div>`;
  }
}
